<template>
  <div>
    <full-calendar
      class="vr_calendar" 
      default-view="dayGridMonth" 
      :plugins="calendarPlugins" 
      :events="events" 
      v-on:eventClick="eventClick">
    </full-calendar>
  </div>
</template>

<script>
  import FullCalendar from '@fullcalendar/vue'
  import dayGridPlugin from '@fullcalendar/daygrid'

  export default {
    name: 'Calendar',

    components: {
      FullCalendar
    },

    data() {
      return {
        calendarPlugins: [ dayGridPlugin ]
      }
    },

    props: ['events'],

    methods: {
      eventClick: function (event, ev, coords) {
        // console.log(event)
        window.location = event.url
      }
    }
  }
</script>

<style lang="scss">

  @import '~@fullcalendar/core/main.css';
  @import '~@fullcalendar/daygrid/main.css';

  .vr_calendar .fc-day-grid-container {
    height: auto!important;
  }

  .vr_calendar {
    max-width: none;
    margin-bottom: 2em;
    font-family: 'PT Sans Narrow', sans-serif;
    background: #fff;
    border:1px solid #d9d9d9;
    padding:20px;

    .fc-day-grid {

      .fc-row {
        min-height: 5em;

        .fc-today { background-color: #F3F3F3; }

        .fc-event {
          background-color: #F3F3F3;
          color: #277DDE;
          padding: 5px;
          height: auto;
          white-space: normal;
          max-height: 60px;
          transition: background-color 0.3s ease-in-out;
          overflow: hidden;
          border-radius: none;
          border: none;
          line-height: 1.2em;

          @media only screen and (min-width: 56em) {
            font-size: 1em;
          }

          &:hover {
            background-color: #277DDE;
            color: #FFF;
          }

          .fc-content {
            white-space: unset;
          }
        }
      }
    }

    .fc-day-header {
      color: #aa9157;
      padding:5px;
      font-weight: normal;
      text-transform: uppercase;
    }

    .fc-toolbar {
      margin-bottom: 1em;
      h2 {
        color: #303030;
        font-size: 1.5em;
      }
    }

    .fc-button-primary, .fc-button-primary:disabled {
      background-color: #AA9156;
      border-color: #AA9156;
    }

    .fc-button {
      padding: 0.3em 0.5em;
    }

    .fc-icon {
      line-height: 0.75em;
    }

    .day-number {
      font-size: 1.2em;
      padding-right: 5px;
    }
  }
</style>