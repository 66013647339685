// Shows and hides school description field on employment form
// Can't think of a better way to do this
// No real access to the HTML of the element
// old school pain commence
function showHideSchoolDescription() {
  const schoolDescription = document.getElementById(
    'form-input-schoolDescription');
  const attendingSchool = document.getElementById(
    'form-input-currentlyAttendingSchool');

  if (schoolDescription) {
    const schoolDescriptionParent = schoolDescription.parentNode;

    // hide school description
    schoolDescriptionParent.classList.add('freeform-hidden')

    attendingSchool.addEventListener("change", () => {
      attendingSchool.checked ?
        schoolDescriptionParent.classList.remove('freeform-hidden') :
        schoolDescriptionParent.classList.add('freeform-hidden')
    })
  }
}

//
// Validate an email address
function checkEmailAddress() {
  const emailAddress = document.getElementById('EMail');
  const subForm = document.getElementById('subForm');

  if (emailAddress) {
    subForm.addEventListener("submit", (e) => {
      var emailValue = emailAddress.value;
      var reg =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
      if (reg.test(emailValue) == false) {
        e.preventDefault();
        alert("Email address must be valid ( email@example.com )");
        return false;
      }
    })
  }
}

export {
  showHideSchoolDescription,
  checkEmailAddress
}
