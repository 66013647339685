<template>
  <div>
    <!--div class="gallery-nav">
      <button :class="{ active: (currentCategory == 'All') }" class="btn btn--filter" @click="filterImages('All')">All</button>
      <button :class="{  active: (currentCategory == cat) }" class="btn btn--filter" v-for="cat in categories" :key="cat" @click.prevent="filterImages(cat)">{{ cat }}</button>
    </div-->

    <flickity id="gallery" ref="flickity" :options="flickityOptions">
      <img v-for="image in filteredImages" class="carousel-cell" :src="image.url" :alt="image.caption" :key="image.url">
    </flickity>

    <div class="caption-wrapper"><span class="caption">{{ caption }}</span></div>
    <button class="btn gallery-prev" @click="previous()"><svg class="icon icon-angle-left">
        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-angle-left"></use>
      </svg></button>
    <button class="btn gallery-next" @click="next()"><svg class="icon icon-angle-right">
        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-angle-right"></use>
      </svg></button>
  </div>
</template>

<script>
import imagesLoaded from 'imagesloaded'
import Flickity from 'vue-flickity'

export default {
  name: 'Gallery',

  components: {
    'flickity': Flickity
  },

  props: {
    images: {
      type: Array
    },

    categories: {
      type: Array
    }
  },

  data: function () {
    return {
      show: false,
      filteredImages: this.images,
      caption: this.images[0].caption,
      currentCategory: 'All',
      flickityOptions: {
        cellSelector: '.carousel-cell',
        imagesLoaded: true,
        percentPosition: false,
        initialIndex: 0,
        prevNextButtons: false,
        pageDots: false,
        wrapAround: true
      },
    }
  },

  watch: {
    filteredImages: function () {
      imagesLoaded(this.$el, () => {
        this.$refs.flickity.rerender()
        this.bindEvents()
        this.setCaption()
      });
    }
  },

  mounted() {
    this.bindEvents();
    this.setCaption();

    imagesLoaded(this.$el, () => {
      this.$refs.flickity.rerender();
      this.bindEvents();
      this.setCaption();
    });
  },

  methods: {

    bindEvents() {
      this.$refs.flickity.on('settle', () => {
        this.setCaption();
      })
    },

    next() {
      this.$refs.flickity.next();
    },

    previous() {
      this.$refs.flickity.previous();
    },

    select(num) {
      this.$refs.flickity.select(num, false, true);
      this.show = true;
    },

    setCaption() {
      this.caption = this.getSelectedAltText();
    },

    getSelectedAltText() {
      var idx = this.$refs.flickity.selectedIndex();
      var tdx = this.$refs.flickity.$flickity?.cells?.length || 0;
      let caption = this.filteredImages[idx].caption;
      return `${idx + 1} of ${tdx} ${caption && '| ' + caption}`;
    },

    filterImages(cat) {

      this.currentCategory = cat;

      if (cat == 'All') {
        this.filteredImages = this.images;
      } else {
        this.filteredImages = this.images.filter(item => {
          for (var index = 0; index < item.categories.length; index++) {
            if (item.categories[index] == cat) {
              return true;
            }
          }
        })
      }

    }

  }
}
</script>
