<template>
  <div id="image-map"></div>
</template>

<script>
  import L from 'leaflet'

  // check out http://omarriott.com/aux/leaflet-js-non-geographical-imagery/
  // map just won't center right

  export default {
    name: 'ImageMap',

    props: {
      width: {
        type: Number,
        default: 2000
      },

      height: {
        type: Number,
        default: 1500
      }
    },

    mounted: function () {
      this.initMap()
    },

    methods: {
      initMap: function() {

        const mapContainer = document.getElementById('image-map');
        const width = Number(mapContainer.clientWidth) * 1.375;
        const height = width * 0.6;
        const url = '/assets/img/vra-seating-map-outlined.svg';

        var map = L.map('image-map', {
          crs: L.CRS.Simple,
          attributionControl: false,
          scrollWheelZoom: false,
          center: [width / 2, height / 2],
          zoom: 0,
        });

        L.imageOverlay(url, [
          [0,0], [width, height]
        ]).addTo(map);

        var legend = L.control({position: 'bottomright'});

        legend.onAdd = function (map) {

            var div = L.DomUtil.create('div', 'leaflet-info leaflet-legend'),
                colors = ["#E8D2AB", "#E8D3E0", "#F2CBCB", "#EBE2C1", "#CEE6CD", "#DEDFFF"],
                labels = ["Jardine Tables", "Level 100", "Level 200", "Luxury Box Suites", "GA Lawn", "Accessible"];

            // loop through and generate a label with a colored square for each interval
            var listHTML = "<ul>";
            for (var i = 0; i < colors.length; i++) {
                listHTML +=
                    '<li><i style="background:' + colors[i] + '"></i> ' + labels[i] + '</li>';
            }
            listHTML += "</ul>"
            div.innerHTML += listHTML;
            return div;
        };

        legend.addTo(map);



        /*
        // HELPER TO FIND CENTER BY PLAYING WITH MAP
        map.on('moveend', function() {
          var center = map.getCenter();
          console.log(center);
        });
        */

      }

    }
  }
</script>

<style>
  #image-map {
    width: 100%;
    aspect-ratio: 5 / 3;
    border: 1px solid #ccc;
    margin-bottom: 6vh;
    z-index: 99;
  }
</style>
