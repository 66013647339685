<script>
export default {
  name: "EventDayTeaser",

  data() {
    return {
      showTeaser: false
    }
  },

  mounted() {
    window.addEventListener('load', () => {
      this.showTeaser = true
    })
  },

  methods: {
    close: function () {
      this.showTeaser = false
    },
  }
}
</script>
