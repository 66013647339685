<template>
  <modal
    name="alert"
    width="100%"
    height="auto"
    :scrollable="true"
    :adaptive="true"
    transition="fade-down"
    classes=""
    :clickToClose="true"
    @before-close="beforeClose">

    <div class="modal-container">
      <div>
        <button @click="closeAlert()" role="button">
          <svg class="icon icon-close"><use xlink:href="#icon-close"></use></svg>
        </button>

        <slot></slot>

      </div>
    </div>

  </modal>
</template>

<script>
import { EventBus } from "../lib/event-bus.js";

const cookieName = "vinaroblesamphitheatre_alert_09092021"

export default {
  name: "Alert",

  props: {
    cookieSuffix: {
      default: '',
      type: String
    }
  },

  data: function () {
    return {
      show: this.open
    }
  },

  mounted() {
    const cookieValue = this.$cookie.get(cookieName + this.cookieSuffix)
    // const cookieValue = false

    if (!cookieValue) {
      this.openAlert()
    } else {

    }
  },

  created() {
    EventBus.$on('close-alert', () => this.closeAlert());
  },

  methods: {
    openAlert() {
      this.$modal.show('alert');
    },
    closeAlert() {
      this.$modal.hide('alert');
    },
    beforeClose() {
      this.$cookie.set(cookieName + this.cookieSuffix, true);
    }
  }
}
</script>
