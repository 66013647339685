//
// This file will be ran through babel so you can use es2015 features.
// Files ending in .vue will be automatically recognized as Vue components
// Files ending in .js or .es6 will be ran through babel, you can just use regular javascript too


import Vue from 'vue';
import vueSmoothScroll from 'vue-smoothscroll'
import Wallop from 'wallop';
import addtocalendar from './lib/addtocalendar.js';
import Flickity from 'vue-flickity';
import {
  showHideSchoolDescription,
  checkEmailAddress
}
from './lib/helpers.js'

// Vue components
import Alert from './components/Alert.vue'
import Calendar from './components/Calendar'
import ImageMap from './components/ImageMap'
import VenueMap from './components/VenueMap'
import Gallery from './components/Gallery'
import EventDayTeaser from './components/EventDayTeaser'

// Vue Modal
// https://github.com/euvl/vue-js-modal
import VModal from 'vue-js-modal'
Vue.use(VModal)

// Vue Cookie
import VueCookie from 'vue-cookie'
Vue.use(VueCookie)

Vue.use(vueSmoothScroll);

var app = new Vue({
  el: '#app',

  data: {
    'hash': window.location.hash.split('#')[1], // used for accordians
    'show_event_calendar': false
  },

  components: {
    'alert': Alert,
    'image-map': ImageMap,
    'venue-map': VenueMap,
    'calendar': Calendar,
    'gallery': Gallery,
    'calendar': Calendar,
    'eventdayteaser': EventDayTeaser
  },

  mounted: function() {
    if (this.hash) {
      window.scrollTo(0, 0)
      const el = document.getElementById(this.hash)
      this.$SmoothScroll(el, 1000);
    }
    window.addEventListener("hashchange", () => {
      this.hash = window.location.hash.split('#')[1]
    }, false);

    // toggles school description on employment form
    showHideSchoolDescription();

    // check mailing list email address
    checkEmailAddress();

    this.initWallop();
  },

  methods: {
    initWallop: function() {
      const sliderEl = document.querySelector('.Wallop');

      if (sliderEl) {
        this.slider = new Wallop(sliderEl);

        const paginationDots = Array.prototype.slice.call(document.querySelectorAll('.Wallop-dot'));
        
        if (paginationDots.length > 1) {
          this.initPaginationDots(paginationDots);

          let nextTimeout = this.getNewSliderTimer();

          this.slider.on('change', (event) => {
            this.changePaginationDot(event, paginationDots);

            // start slide timer over
            clearTimeout(nextTimeout);
            nextTimeout = this.getNewSliderTimer();
          });

          // on mouse enter stop the timer and pause
          sliderEl.addEventListener('mouseenter', () => {
            clearTimeout(nextTimeout);
          });

          // on mouse out start a new timer
          sliderEl.addEventListener('mouseleave', () => {
            nextTimeout = this.getNewSliderTimer();
          })
        }
      }
    },

    initPaginationDots: function(paginationDots) {
      paginationDots.forEach((dotEl, index) => {
        dotEl.addEventListener('click', () => {
          this.slider.goTo(index);
        });
      });
    },

    changePaginationDot: function(event, paginationDots) {
      let currentDot = document.querySelector('.Wallop-dot--current');
      let newCurrentDot = paginationDots[event.detail.currentItemIndex];

      currentDot.classList.remove('Wallop-dot--current');
      newCurrentDot.classList.add('Wallop-dot--current');
    },

    getNewSliderTimer: function(clear) {
      return setTimeout(() => {
        this.loadNextSlide();
      }, 6000);
    },

    loadNextSlide: function() {
      this.slider.next()
    }
  }
})
