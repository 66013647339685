<template>
  <div id="venue-map"></div>
</template>

<script>
  import L from 'leaflet'

  // check out http://omarriott.com/aux/leaflet-js-non-geographical-imagery/
  // map just won't center right

  export default {
    name: 'VenueMap',

    mounted: function () {
      this.initMap()
    },

    methods: {
      initMap: function() {

        const mapContainer = document.getElementById('venue-map');
        const width = Number(mapContainer.clientWidth) * 1.375;
        const height = width * 0.75;
        const url = '/assets/img/vra-venue-map-vector-min.svg';

        let map = L.map('venue-map', {
          crs: L.CRS.Simple,
          attributionControl: false,
          scrollWheelZoom: false,
          center: [width / 2, height / 2],
          zoom: 0,
        });

        L.imageOverlay(url, [
          [0,0], [width, height]
        ]).addTo(map);

      },

    }
  }
</script>

<style>
  #venue-map {
    width: 100%;
    aspect-ratio: 4 / 3;
    border: 1px solid #ccc;
    margin-bottom: 6vh;
    z-index: 99;
  }
</style>
